import styled from 'styled-components';
import { down } from 'styled-breakpoints';

export const ComponentWrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
`;

export const Container = styled.div`
  overflow: hidden;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
`;

export const Carousel = styled.div`
  display: flex;
  gap: 24px;
  padding: 0 0 128px;
`;

export const Card = styled.div`
  min-width: 1016px;
  width: calc(100vw - 424px);
  display: flex;
  flex-direction: column;
  padding: 80px 128px;
  position: relative;
  gap: 32px;
  border-radius: 16px;
  background: #083344;
  align-items: center;

  ${down('md')} {
    min-width: 311px;
    width: calc(100vw - 96px);
    padding: 24px;
  }
`;

export const Blur = styled.div`
  position: absolute;
  top: -6px;
  height: 492px;
  width: 492px;
  left: calc(50% - 246px);
  border-radius: 492px;
  opacity: 0.15000000596046448;
  background: #ffffff;
  filter: blur(150px);
`;

export const Logo = styled.div`
  margin: 0 auto;
`;

export const Text = styled.span`
  max-width: 760px;
  color: #ffffff;
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 38px;

  ${down('md')} {
    font-size: 16px;
    line-height: 25px;
  }
`;

export const AvatarContainter = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  height: 64px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
`;

export const Name = styled.span`
  color: #ffffff;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;
`;
export const Info = styled.span`
  color: #ffffff;
  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
  line-height: 140%;
`;

export const ButtonLeft = styled.button`
  display: flex;
  position: absolute;
  left: calc(50% - 468px);
  top: calc(50% - 28px);
  width: 56px;
  height: 56px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: #3b5d6a;
  border: 0;
  outline: 0;
  box-shadow: 0;
  ${down('md')} {
    left: calc(50% - 64px);
    top: calc(50% + 186px);
  }
`;
export const ButtonRight = styled.button`
  display: flex;
  position: absolute;
  right: calc(50% - 468px);
  top: calc(50% - 28px);
  width: 56px;
  height: 56px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: #3b5d6a;
  border: 0;
  outline: 0;
  box-shadow: 0;
  ${down('md')} {
    right: calc(50% - 64px);
    top: calc(50% + 186px);
  }
`;
