import { FC } from 'react';
import { Icons } from '@studenten/ui-components';
import useCollapse from 'react-collapsed';

import {
  Wrapper,
  Header,
  HeaderText,
  IconWrapper,
  Content,
  TextContentWrapper,
  QuestionText,
  AnsverText,
} from './Accordion.styled';

export type AccordionProps = {
  header: string;
  text: string;
  buildSchemaJson?: boolean;
};

const Accordion: FC<AccordionProps> = ({ header, text }) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  return (
    <Wrapper>
      <Header {...getToggleProps()}>
        <HeaderText>
          <QuestionText>{header}</QuestionText>
        </HeaderText>
        <IconWrapper isExpanded={isExpanded}>
          <Icons.AccordionArrow aria-hidden={true} />
        </IconWrapper>
      </Header>
      <Content {...getCollapseProps()}>
        <TextContentWrapper>
          <AnsverText>{text}</AnsverText>
        </TextContentWrapper>
      </Content>
    </Wrapper>
  );
};

export default Accordion;
