import styled from 'styled-components';
import { down } from 'styled-breakpoints';

export const Container = styled.section`
  width: 100%;
  background-color: ${({ theme }) => theme.business.cyan};
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  gap: 32px;
`;

export const H1 = styled.h1`
  display: flex;
  flex-direction: column;
  max-width: 1016px;
  text-align: center;
  font-style: normal;
  font-family: Inter;
  line-height: 140%;
  font-weight: 600;
  font-size: 32px;
  color: #ffffff;
  margin: 80px auto 0;

  ${down('md')} {
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
  }
`;

export const SubHeader = styled.p`
  text-align: center;
  font-style: normal;
  font-family: Inter;
  line-height: 25px;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  margin: 0 auto 80px;

  ${down('md')} {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
`;

export const Blur = styled.div`
  width: 492px;
  height: 492px;
  position: absolute;
  bottom: -48px;
  left: calc(50% - 246px);
  display: block;
  border-radius: 492px;
  opacity: 0.15000000596046448;
  background: #ffffff;
  filter: blur(150px);
  overflow-x: hidden;
  max-width: 100vw;
`;

export const QuotesUpContainer = styled.div`
  width: 230.666px;
  height: 168.913px;
  transform: rotate(-0.519deg);
  position: absolute;
  left: 0px;
  top: 14px;
  fill: linear-gradient(135deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  opacity: 0.30000001192092896;
  overflow: hidden;
`;

export const QuotesDownContainer = styled.div`
  width: 230.666px;
  height: 168.913px;
  transform: rotate(356deg);
  position: absolute;
  left: calc(100% - 237px);
  top: calc(100% - 148px);
  fill: linear-gradient(137deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  opacity: 0.30000001192092896;
  overflow: hidden;
`;
