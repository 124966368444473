import { FC, memo } from 'react';

import { MetaTags } from 'src/common/Seo';
import { Hero } from './components/Hero';
import { Services } from './components/Services';

import type { Props } from './types';
import { Quote } from './components/Quote';
import { FAQ } from './components/FAQ';
import { Explore } from './components/Explore';
import { Testimonials } from './components/Testimonials';
import { BusinessLayout } from '../../components/Layouts';
import { LatestStudentsFeed } from './components/LatestStudentsFeed/LatestStudentsFeed';
import * as React from 'react';
import { TextContentSection } from './components/TextContentSection/TextContentSection';

const isProd = process.env.NEXT_PUBLIC_APP_CONTEXT === 'production';

const Werkgever: FC<Props> = ({ strapiContent }) => {
  const { attributes } = strapiContent || {};
  const { seoMeta, faq, titleHero, textHero, services, quote, explore, testimonials, textContent } =
    attributes || {};

  const accordionData = faq?.items.map(({ question: header, answer: text, buildSchemaJson }) => ({
    header,
    text,
    buildSchemaJson,
  }));

  return (
    <>
      <MetaTags title={seoMeta?.title} description={seoMeta?.description} canonicalUrl={isProd} />
      <BusinessLayout>
        <Hero title={titleHero} text={textHero} />
        <Services title={services?.titleServices} items={services?.items} />
        <Quote title={quote?.quoteText} origin={quote?.quoteOrigin} />
        <LatestStudentsFeed />
        <Explore explore={explore} />
        {!!textContent?.richText && <TextContentSection richText={textContent.richText} />}
        {!!faq && accordionData?.length && <FAQ title={faq.title} accordionData={accordionData} />}
        <Testimonials testimonials={testimonials} />
      </BusinessLayout>
    </>
  );
};

export default memo(Werkgever);
