import styled from 'styled-components';

export const ComponentWrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 64px;
  max-width: 1160px;
  padding: 32px 16px 48px;
  margin: 0 auto;
`;
