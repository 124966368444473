import { FC, memo } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import backgroundImageDaan from '/public/assets/placeholders/Daan.png';
import backgroundImageEmma from '/public/assets/placeholders/Emma.png';
import backgroundImageLucas from '/public/assets/placeholders/Lucas.png';
import backgroundImageEvi from '/public/assets/placeholders/Evi.png';
import { APP_ROUTES } from 'constants/routes';

import {
  Container,
  TextSection,
  H1,
  SubHeader,
  GetStartedLink,
  CardHeader,
  CardTitle,
  Card,
  CardsContainer,
  CardStatus,
  CardTag,
  CardTags,
  Blur,
  CardsContainerWrapper,
} from './Hero.styled';

export type HeroProps = {
  title?: string;
  text?: string;
};

const Hero: FC<HeroProps> = ({ title, text }) => {
  return (
    <Container>
      <Blur />
      <TextSection>
        <H1>{title}</H1>
        <SubHeader>{text}</SubHeader>
      </TextSection>
      <CardsContainerWrapper>
        <CardsContainer>
          <Card imageUrl={backgroundImageEvi.src}>
            <CardHeader>
              <CardTitle>Evi</CardTitle>
              <CardStatus>
                <Image src="/assets/indicator.svg" alt="indicator" width={8} height={8} />
                Recently active
              </CardStatus>
            </CardHeader>
            <CardTags>
              <CardTag>HBO Bachelor, Interdisciplinary</CardTag>
            </CardTags>
            <CardTags>
              <CardTag>Utrecht</CardTag>
              <CardTag>Leuk bijbaantje</CardTag>
            </CardTags>
            <CardTags>
              <CardTag>8-12 uur p/w</CardTag>
              <CardTag>Klantenservice</CardTag>
            </CardTags>
          </Card>
          <Card imageUrl={backgroundImageEmma.src}>
            <CardHeader>
              <CardTitle>Emma</CardTitle>
              <CardStatus>
                <Image src="/assets/indicator.svg" alt="indicator" width={8} height={8} />
                Recently active
              </CardStatus>
            </CardHeader>
            <CardTags>
              <CardTag>WO Bachelor, Behavior and Society</CardTag>
            </CardTags>
            <CardTags>
              <CardTag>Rotterdam</CardTag>
              <CardTag>Leuk bijbaantje</CardTag>
            </CardTags>
            <CardTags>
              <CardTag>12-16 uur p/w</CardTag>
              <CardTag>Zorg</CardTag>
            </CardTags>
          </Card>
          <Card imageUrl={backgroundImageLucas.src}>
            <CardHeader>
              <CardTitle>Lucas</CardTitle>
              <CardStatus>
                <Image src="/assets/indicator.svg" alt="indicator" width={8} height={8} />
                Recently active
              </CardStatus>
            </CardHeader>
            <CardTags>
              <CardTag>WO Master, Art and Culture</CardTag>
            </CardTags>
            <CardTags>
              <CardTag>Groningen</CardTag>
              <CardTag>Veel uren werken</CardTag>
            </CardTags>
            <CardTags>
              <CardTag>12-16 uur p/w</CardTag>
              <CardTag>IT</CardTag>
              <CardTag>Horeca</CardTag>
            </CardTags>
          </Card>
          <Card imageUrl={backgroundImageDaan.src}>
            <CardHeader>
              <CardTitle>Daan</CardTitle>
              <CardStatus>
                <Image src="/assets/indicator.svg" alt="indicator" width={8} height={8} />
                Recently active
              </CardStatus>
            </CardHeader>
            <CardTags>
              <CardTag>HBO Bachelor, Economy and Business</CardTag>
            </CardTags>
            <CardTags>
              <CardTag>Amsterdam</CardTag>
              <CardTag>Veel uren werken</CardTag>
            </CardTags>
            <CardTags>
              <CardTag>20-40 uur p/w</CardTag>
              <CardTag>IT</CardTag>
              <CardTag>Zorg</CardTag>
            </CardTags>
          </Card>
        </CardsContainer>
      </CardsContainerWrapper>
      <Link passHref href={APP_ROUTES.PARTNERS_SIGN_UP} prefetch={false}>
        <GetStartedLink>
          Studenten Inhuren
          <Image src="/assets/rightWhite.svg" alt="Arrow" width={24} height={24} />
        </GetStartedLink>
      </Link>
    </Container>
  );
};

export default memo(Hero);
