import React, { useEffect } from 'react';
import { APP_ROUTES } from '../../../../../constants/routes';
import { InhurenStudentFeedCard } from '../../../../components/Cards/InhurenStudentFeed/InhurenStudentFeedCard';
import { useStore } from '../../../../../features/search/models/studentsSearch';
import { getTagsFromStudent, getUsernameFromStudent } from '../../../../../utils/helpers';
import { observer } from 'mobx-react';
import { prepareStudentSlug } from '../../../../../api_entities/student/helpers';
import {
  ComponentWrapper,
  Container,
  StyledLatestStudentsFeed,
  StyledHead2,
  StyledFeedGrid,
} from './LatestStudents.styled';

const TOTAL_STUDENTS = 4000;

export const LatestStudentsFeed = observer(() => {
  const store = useStore();

  useEffect(() => {
    store.searchStudents({ limit: 12 });
  }, []);

  if (store.isFetching) {
    return null;
  }

  return (
    <StyledLatestStudentsFeed>
      <ComponentWrapper>
        <Container>
          <StyledHead2>{store.total + TOTAL_STUDENTS} Studenten Direct Beschikbaar</StyledHead2>
          <StyledFeedGrid>
            {store.data.map((student) => {
              const slug = prepareStudentSlug(student);
              const href = `${APP_ROUTES.WERKGEVER}/${slug}`;
              return (
                <InhurenStudentFeedCard
                  key={student.objectID}
                  href={href}
                  imgSrc={student.profileImg}
                  username={getUsernameFromStudent(student)}
                  tags={getTagsFromStudent(student)}
                />
              );
            })}
          </StyledFeedGrid>
        </Container>
      </ComponentWrapper>
    </StyledLatestStudentsFeed>
  );
});
