import { fetcher } from 'api_entities/fetch';
import { SearchStudentResponse, StudentCard } from './types';

export const profileApi = {
  getStudentCardByCity: async function (city: string) {
    const url = `${process.env.NEXT_PUBLIC_API}/api/students/students-city?city=${city}`;
    const { data } = await fetcher.get(url);
    return data;
  },
  getStudentCardByJob: async function (job: string) {
    const url = `${process.env.NEXT_PUBLIC_API}/api/students/students-job?job=${job}`;
    const { data } = await fetcher.get(url);
    return data;
  },
  getStudentById: async function (studentId: string) {
    const url = `${process.env.NEXT_PUBLIC_API}/api/students/${studentId}`;
    const { data } = await fetcher.get<StudentCard>(url);
    return data;
  },

  searchStudents: async function (query: Record<string, any>) {
    const searchParams = new URLSearchParams();

    Object.entries(query).forEach(([key, value]) => {
      if (value instanceof Array) {
        value.forEach((element, idx) => searchParams.append(`${key}[${idx}]`, element));
      } else {
        value && searchParams.append(key, value);
      }
    });

    const url = `${process.env.NEXT_PUBLIC_API}/api/students/search?${searchParams.toString()}`;

    const { data } = await fetcher.get<SearchStudentResponse>(url);
    return data;
  },
};
