import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';

export const Container = styled.section`
  width: 100%;
  background-color: ${({ theme }) => theme.business.cyan};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 152px;
  padding-bottom: 96px;
  position: relative;

  ${down('md')} {
    padding-top: 64px;
  }
`;

export const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 1440px;
`;

export const H1 = styled.h1`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-self: stretch;
  font-style: normal;
  font-family: Inter;
  line-height: 120%;
  font-weight: 700;
  font-size: 64px;
  margin: 16px 0;

  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0.5) 95.24%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #000000;

  ${down('md')} {
    font-size: 40px;
    font-weight: 700;
    line-height: 130%;
  }
`;

export const SubHeader = styled.p`
  font-size: 24px;
  line-height: 38px;
  font-family: Inter;
  font-weight: 400;
  text-align: center;

  color: rgba(255, 255, 255, 0.8);

  ${down('md')} {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
  }
`;

export const NavLink = styled.a<{ isDark?: boolean }>`
  display: flex;
  gap: 9px;
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${({ theme }) => theme.business.white};
  transition: color 0.1s ease-in;
  img {
    vertical-align: middle;
  }
`;

export const GetStartedLink = styled(NavLink)`
  position: relative;
  display: flex;
  padding: 16px 32px;
  background-color: #1dbf70;
  border-radius: 8px;
  transition: background-color 0.1s ease-in;
  margin: 40px auto 0;
  align-items: center;
  &:hover {
    background-color: #20d47c;
  }

  ${down('md')} {
    width: calc(100% - 32px);
    justify-content: center;
    font-size: 16px;
    line-height: 130%;
  }
`;

export const CardsContainerWrapper = styled.div`
  overflow-x: auto;
`;

export const CardsContainer = styled.div`
  display: flex;
  gap: 24px;
  margin: 0 auto;
  padding-top: 40px;
  width: max-content;

  ${down('md')} {
    margin: 0 16px;
  }
`;

export const Card = styled.div<{ imageUrl: string }>`
  width: 288px;
  height: 384px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 16px;
  ${({ imageUrl }) => css`
    background: linear-gradient(180deg, rgba(8, 51, 68, 0) 40%, #083344 100%), url(${imageUrl}),
      lightgray 50% \;;
  `}
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0px 14px 50px 0px rgba(5, 28, 37, 0.89);
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 12px;
`;

export const CardTitle = styled.span`
  color: #ffffff;
  font-size: 32px;
  font-family: Inter;
  font-weight: 700;
  line-height: 130%;
`;

export const CardStatus = styled.span`
  color: #ffffff;
  font-size: 12px;
  font-family: Inter;
  line-height: 140%;
  display: flex;
  gap: 4px;
  justify-content: center;
`;

export const CardTags = styled.div`
  padding: 0 8px 8px;
  display: flex;
  gap: 4px;
`;

export const CardTag = styled.span`
  display: flex;
  height: 32px;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  width: fit-content;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);

  color: #ffffff;
  font-size: 12px;
  font-family: Inter;
  line-height: 140%;
`;

export const Blur = styled.div`
  width: 752px;
  height: 752px;
  position: absolute;
  top: -109px;
  left: calc(50% - 376px);
  display: block;
  border-radius: 752px;
  opacity: 0.15000000596046448;
  background: #fff;
  filter: blur(150px);
  overflow-x: hidden;
  max-width: 100vw;
`;
