import React from 'react';
import { ComponentWrapper, Container } from './TextContentSection.styled';
import { RichText } from '../../../../components/RichText';
import { richtextOptionsBusiness } from '../../../../components/RichText/Richtext.options';

export type TextContentSectionProps = {
  richText: string;
};

export const TextContentSection: React.FC<TextContentSectionProps> = ({ richText }) => {
  return (
    <ComponentWrapper>
      <Container>
        <RichText options={richtextOptionsBusiness}>{richText}</RichText>
      </Container>
    </ComponentWrapper>
  );
};
