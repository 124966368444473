import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';

export const ComponentWrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 64px;
  max-width: 1160px;
  padding: 96px 16px;
  margin: 0 auto;
`;

export const Button = styled.a`
  width: fit-content;
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  color: #1dbf70;
  transition: color 0.1s ease-in;
  position: relative;
  display: flex;
  padding: 9px 32px;
  border: 1px solid #1dbf70;
  border-radius: 8px;
  transition: border-color 0.1s ease-in;
  margin-top: 16px;

  &:hover {
    border-color: #0891b2;
    color: #0891b2;
  }

  ${down('md')} {
    width: 100%;
    justify-content: center;
  }
`;

export const ExploreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${Button} {
    display: none;
    ${down('md')} {
      display: flex;
    }
  }
`;

export const Title = styled.h1`
  color: #083344;
  font-family: Inter;
  font-size: 48px;
  font-weight: 700;
  line-height: 130%;
  margin-bottom: 32px;

  ${down('md')} {
    font-size: 32px;
  }
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${down('md')} {
    flex-direction: row;
  }
`;

export const TagsGrid = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  max-width: 870px;
  flex-wrap: wrap;
  ${down('md')} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 32px;
    row-gap: 24px;
  }
`;

export const Tag = styled.a<{ isAllVisibleRows?: boolean }>`
  text-decoration: none;
  padding: 8px 11px;
  border-radius: 8px;
  border: 1px solid #0e7490;
  color: #0e7490;
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  line-height: 130%;
  white-space: nowrap;

  &:hover {
    color: #ef7d00;
    border: 1px solid #ef7d00;
  }
  &:active {
    color: #ffffff;
    border: 0;
    background: #232d5c;
  }

  ${down('md')} {
    border: none;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    ${({ isAllVisibleRows }) =>
      !isAllVisibleRows &&
      css`
        &:nth-child(n + 13) {
          display: none;
        }
      `}
  }
`;
