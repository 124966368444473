import { Werkgever } from '../../src/templates/Werkgever-home';

import { GetStaticProps } from 'next';
import werkgever from '../../api_entities/strapi/werkgever';
import { logger } from '../../utils/logger';

import { WerkgeverPageContent } from '../../api_entities/strapi/types';

export const getStaticProps: GetStaticProps = async () => {
  let strapiContent: WerkgeverPageContent | null;
  try {
    const params = new URLSearchParams();
    params.append('limit', '3');

    const [_strapiContent] = await Promise.allSettled([werkgever.fetchData()]);

    strapiContent = _strapiContent.status === 'fulfilled' ? _strapiContent.value : null;

    return {
      props: {
        ...(strapiContent && { strapiContent }),
      },
      revalidate: 60,
    };
  } catch (e) {
    logger.error(e);
    logger.warn('Failed to render a "werkgever".');

    return {
      notFound: true,
    };
  }
};

export default Werkgever;
