import { makeAutoObservable, runInAction } from 'mobx';
import { enableStaticRendering } from 'mobx-react';
import { useMemo } from 'react';
import { StudentCard } from '../../../api_entities/student/types';
import { profileApi } from '../../../api_entities/student';

enableStaticRendering(typeof window === 'undefined');

let store: StudentsSearchStore;
export class StudentsSearchStore {
  data: StudentCard[] = [];
  total = 0;
  isFetching = false;

  constructor() {
    makeAutoObservable(this);
  }

  async searchStudents(query: Record<string, any>) {
    runInAction(() => {
      this.isFetching = true;
    });

    await this.fetchData(query);

    runInAction(() => {
      this.isFetching = false;
    });
  }

  async fetchData(query: Record<string, any>) {
    const response = await profileApi.searchStudents(query);

    runInAction(() => {
      this.data = response.data;
      this.total = response.meta.count;
    });
  }
}

function initializeStore() {
  const _store = store ?? new StudentsSearchStore();

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store;
  // Create the store once in the client
  if (!store) {
    store = _store;
  }

  return _store;
}

export function useStore() {
  return useMemo(() => initializeStore(), []);
}
