import Image from 'next/image';
import { FC, memo, useCallback } from 'react';
import {
  AvatarContainter,
  Blur,
  Card,
  Info,
  InfoContainer,
  Logo,
  Name,
  Text,
  Container,
  Carousel,
  ButtonLeft,
  ButtonRight,
  ComponentWrapper,
} from './Testimonials.styled';
import noLogo from '/public/assets/placeholders/No_logo.svg';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay'
import { TestimonialCardComponent } from 'api_entities/strapi/types';

export type TestimonialsProps = {
  testimonials?: {
    testimonialCard: TestimonialCardComponent[];
  };
};

const Testimonials: FC<TestimonialsProps> = (props) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false, startIndex: 1 }, [Autoplay()]);
  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);
  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);
  return (
    <ComponentWrapper>
      <Container className="embla" ref={emblaRef}>
        <Carousel className="embla__container">
          {props?.testimonials?.testimonialCard.map((card, index) => (
            <Card className="embla__slide" key={index}>
              <Blur />
              <Logo>
                {card.companyLogo.data ? (
                  <Image
                    alt={`Logo ${card.companyLogo.data.attributes.name}`}
                    src={card.companyLogo.data.attributes.url}
                    height={card.companyLogo.data.attributes.height}
                    width={card.companyLogo.data.attributes.width}
                  />
                ) : (
                  <Image alt={`No logo`} src={noLogo} height={56} width={222} />
                )}
              </Logo>
              <Text>{card.testimonialQuote}</Text>
              <AvatarContainter>
                {card.avatar.data ? (
                  <Image
                    alt={`Avatar ${card.testimonialName}`}
                    src={card.avatar.data?.attributes.url}
                    height={card.avatar.data?.attributes.height}
                    width={card.avatar.data?.attributes.width}
                  />
                ) : (
                  // <Image alt={`Default avatar`} src={DefaultAvatar} height={64} width={64} />
                  <></>
                )}
                <InfoContainer>
                  <Name>{card.testimonialName}</Name>
                  <Info>{card.testimonialOccupation}</Info>
                </InfoContainer>
              </AvatarContainter>
            </Card>
          ))}
        </Carousel>
        <ButtonLeft onClick={scrollPrev}>
          <Image src="/assets/caret-left.svg" alt="ButtonLeft" width={24} height={24} />
        </ButtonLeft>
        <ButtonRight onClick={scrollNext}>
          <Image src="/assets/caret-right.svg" alt="ButtonRight" width={24} height={24} />
        </ButtonRight>
      </Container>
    </ComponentWrapper>
  );
};

export default memo(Testimonials);
