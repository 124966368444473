import styled from 'styled-components';
import { down } from 'styled-breakpoints';

export const Container = styled.section<{ backgroundColor?: string }>`
  width: 100%;
  background-color: ${({ theme, backgroundColor }) => backgroundColor || theme.system.white};
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  min-height: 464px;
`;

export const Wrapper = styled.div`
  width: 100%;
  padding: 0 16px 96px;
  max-width: 1160px;
  margin: 0 auto;
`;

export const H2 = styled.h2`
  color: #083344;
  font-family: Inter;
  font-size: 48px;
  font-weight: 700;
  line-height: 130%;
  margin-bottom: 32px;

  ${down('md')} {
    font-size: 32px;
    line-height: 130%;
  }
`;

export const FAQList = styled.div`
  width: 100%;
  & > * {
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }
`;
