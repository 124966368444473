import { FC, memo } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import logoAmsta from '/public/assets/placeholders/Partners/amsta-ColorEffect.png';
import logoConnexxion from '/public/assets/placeholders/Partners/connexxion-ColorEffect.png';
import logoSoleo from '/public/assets/placeholders/Partners/soleo-ColorEffect.png';
import logoDeloitte from '/public/assets/placeholders/Partners/deloitte-ColorEffect.png';
import logoIntersswitch from '/public/assets/placeholders/Partners/interswitch-ColorEffect.png';
import logoTue from '/public/assets/placeholders/Partners/tue-ColorEffect.png';
import logoWageningen from '/public/assets/placeholders/Partners/wageningen-ColorEffect.png';

import {
  Banner,
  BannerButton,
  BannerContainer,
  BannerRow,
  Container,
  H1,
  H2,
  PartnersContainer,
  StatisticsCard,
  StatisticsRow,
  SubHeader,
  PartnersContainerWrapper,
} from './Services.styled';
import { StatisticsCardComponent } from 'api_entities/strapi/types';

const partnerlogos = [
  { src: logoConnexxion.src, width: '222', height: '56' },
  { src: logoAmsta.src, width: '203', height: '56' },
  { src: logoSoleo.src, width: '196', height: '56' },
  { src: logoDeloitte.src, width: '296', height: '56' },
  { src: logoIntersswitch.src, width: '228', height: '56' },
  { src: logoTue.src, width: '253', height: '56' },
  { src: logoWageningen.src, width: '294', height: '56' },
];

export type ServicesProps = {
  title?: string;
  items?: StatisticsCardComponent[];
};

const Layout: FC<ServicesProps> = (props) => {
  return (
    <BannerContainer>
      <Banner>
        <H1>{props.title}</H1>
        <BannerRow>
          <Link href="/werving">
            <BannerButton>
              Werving & Selectie
              <Image src="/assets/right.svg" alt="Arrow" width={24} height={24} />
            </BannerButton>
          </Link>
          <Link href="/uitzendbureau">
            <BannerButton>
              Uitzenden
              <Image src="/assets/right.svg" alt="Arrow" width={24} height={24} />
            </BannerButton>
          </Link>
          <Link href="/jobboard">
            <BannerButton>
              Jobboard
              <Image src="/assets/right.svg" alt="Arrow" width={24} height={24} />
            </BannerButton>
          </Link>
        </BannerRow>
      </Banner>
    </BannerContainer>
  );
};

const Services: FC<ServicesProps> = (props) => {
  return (
    <Container>
      <PartnersContainerWrapper>
        <PartnersContainer>
          {partnerlogos.map((logo, index) => (
            <Image key={index} alt={`Logo ${index}`} {...logo} />
          ))}
        </PartnersContainer>
      </PartnersContainerWrapper>
      <Layout title={props.title} />
      <StatisticsRow>
        {props?.items?.map((card, index) => (
          <StatisticsCard key={index}>
            <H2>{card.Header}</H2>
            <SubHeader>{card.subtext}</SubHeader>
          </StatisticsCard>
        ))}
      </StatisticsRow>
    </Container>
  );
};

export default memo(Services);
