import { FC, memo } from 'react';
import Image from 'next/image';
import quotesUp from '/public/assets/quotesUp.svg';
import quotesDown from '/public/assets/quotesDown.svg';

import {
  Blur,
  Container,
  H1,
  SubHeader,
  QuotesUpContainer,
  QuotesDownContainer,
} from './Quote.styled';

export type QuoteProps = {
  title?: string;
  origin?: string;
};

const Quote: FC<QuoteProps> = (props) => {
  return (
    <Container>
      <Blur />
      <QuotesUpContainer>
        <Image src={quotesUp.src} alt={`Quotes Up pic`} width={230.666} height={168.913} />
      </QuotesUpContainer>
      <QuotesDownContainer>
        <Image src={quotesDown.src} alt={`Quotes Down pic`} width={230.666} height={168.913} />
      </QuotesDownContainer>
      <H1>{props.title}</H1>
      <SubHeader>{props.origin}</SubHeader>
    </Container>
  );
};

export default memo(Quote);
