import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { device } from '../../../../../constants/device';

export const StyledLatestStudentsFeed = styled.div`
  width: 100%;
  background-color: #fff;
`;

export const ComponentWrapper = styled.div`
  padding: 0 108px;
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  position: relative;
  color: ${({ theme }) => theme.business.font.primary};

  @media ${device.laptop} {
    padding: 0 58px;
  }
  @media ${device.mobileL} {
    padding: 0 16px;
  }
`;

export const Container = styled.div`
  padding: 24px 0;

  ${up('lg')} {
    padding: 32px 0;
  }
`;

export const StyledFeedGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(288px, 1fr));
  gap: 16px;

  ${up('lg')} {
    gap: 24px;
  }
`;

export const StyledHead2 = styled.h2`
  font-size: 32px;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 0.75em;

  color: ${({ theme }) => theme.palette.cyan['950']};
`;

