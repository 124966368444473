import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import servicesBanner from '/public/assets/placeholders/services_banner.png';

export const Container = styled.section`
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const PartnersContainerWrapper = styled.div`
  position: relative;
  overflow-x: auto;
  overflow: hidden;
`;

export const PartnersContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 48px;
  height: 56px;
  width: max-content;
  margin: 96px auto 0;
  padding: 0 16px;
`;

export const Partners = styled.div`
  display: flex;
  flex-direction: row;
  height: 56px;
  white-space: nowrap;
  justify-content: center;
  gap: 80px;
  position: absolute;
  top: 0;
  width: max-content;
`;

export const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;

  ${down('md')} {
    padding: 64px 16px 0;
  }
`;

export const Banner = styled.div`
  max-width: 1224px;
  width: 100%;
  padding: 266px 64px 64px 64px;
  margin: 96px auto 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  border-radius: 16px;
  gap: 64px;
  background: linear-gradient(90deg, rgba(8, 51, 68, 0.8) 0%, rgba(8, 51, 68, 0) 100%),
    url(${servicesBanner.src}), lightgray 0px -129.5px / 100% 138.071% no-repeat;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-clip: border-box;
  box-shadow: 0px 14px 50px 0px rgba(5, 28, 37, 0.89);

  ${down('md')} {
    max-width: 768px;
    width: 100%;
    padding: 16px;
    margin: 0;
    gap: 0;
    background: linear-gradient(transparent 0 125px, #083344 330px 1000px),
      url(${servicesBanner.src}) -168px -27px no-repeat;
    background-size: 177%;
    background-repeat: no-repeat;
    background-color: #083344;
    box-shadow: none;
  }
`;

export const H1 = styled.h1`
  display: flex;
  flex-direction: column;
  text-align: left;
  align-self: stretch;
  font-style: normal;
  font-family: Inter;
  line-height: 130%;
  font-weight: 700;
  font-size: 48px;
  color: #ffffff;
  max-width: 526px;

  ${down('md')} {
    font-size: 32px;
    line-height: 130%;
    padding: 258px 16px 0;
    margin: 0 0 40px;
  }
`;

export const BannerRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  ${down('xl')} {
    gap: 16px;
    margin: 0 auto;
    flex-direction: column;
    width: 100%;
    padding: 0 16px 16px;
  }
  ${down('md')} {
    padding: 0;
  }
`;

export const BannerButton = styled.a`
  display: flex;
  gap: 9px;
  font-size: 18px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  color: #083344;
  transition: color 0.1s ease-in;

  &:hover {
    color: #0e7490;
  }

  img {
    vertical-align: middle;
  }

  position: relative;
  width: 256px;
  height: 73px;
  padding: 24px;
  background-color: #ffffff;
  border-radius: 16px;
  justify-content: center;

  ${down('md')} {
    padding: 24px 0;
    width: 100%;
  }
`;

export const StatisticsRow = styled.div`
  max-width: 1224px;
  width: 100%;
  margin: 96px auto 74px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  ${down('lg')} {
    flex-direction: column;
  }
`;

export const StatisticsCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 19px;
  padding: 0 57px;
  text-align: center;
`;

export const H2 = styled.h2`
  align-self: stretch;
  font-style: normal;
  font-family: Inter;
  line-height: 100%;
  font-weight: 900;
  font-size: 112px;
  color: #0e7490;

  ${down('md')} {
    font-size: 88px;
    line-height: 100%;
  }
`;

export const SubHeader = styled.p`
  font-size: 20px;
  line-height: 32px;
  font-family: Inter;
  font-weight: 600;
  color: #083344;

  ${down('md')} {
    font-size: 16px;
    line-height: 25px;
  }
`;
