import { FC, memo, useState } from 'react';

import {
  Container,
  ExploreWrapper,
  Title,
  TagsContainer,
  TagsGrid,
  Tag,
  Button,
  ComponentWrapper,
} from './Explore.styled';
import { LinkComponent } from 'api_entities/strapi/types';

const ExploreCategory: FC<ExploreCategoryProps> = ({ category }) => {
  const [isAllVisibleRows, setIsAllVisibleRows] = useState(false);
  const handleShowAll = () => {
    setIsAllVisibleRows(true);
  };

  return (
    <ExploreWrapper>
      <Title>{category.title}</Title>
      <TagsContainer>
        <TagsGrid>
          {category.pageLinks.map((link, index) => (
            <Tag
              key={index}
              isAllVisibleRows={isAllVisibleRows}
              href={link.href}
              target={link.openNewTab ? '_blank' : undefined}
            >
              {link.label}
            </Tag>
          ))}
        </TagsGrid>
      </TagsContainer>
      {!isAllVisibleRows && category.pageLinks.length > 12 && (
        <Button onClick={handleShowAll}>Toon Alles</Button>
      )}
    </ExploreWrapper>
  );
};

export type ExploreCategoryProps = {
  category: {
    title: string;
    pageLinks: LinkComponent[];
    showMore?: LinkComponent;
  };
};

export type ExploreProps = {
  explore?: ExploreCategoryProps['category'][];
};

const Explore: FC<ExploreProps> = (props) => {
  return (
    <ComponentWrapper>
      <Container>
        {props.explore?.map((category, index) => (
          <ExploreCategory category={category} key={index} />
        ))}
      </Container>
    </ComponentWrapper>
  );
};

export default memo(Explore);
