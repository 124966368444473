import { FC, memo } from 'react';
import Head from 'next/head';

import { Accordion } from './Accordion';
import { AccordionProps } from './Accordion/Accordion';

import { Container, Content, FAQList, H2, Wrapper } from './FAQ.styled';

export type FAQProps = {
  title: string;
  accordionData: AccordionProps[];
  backgroundColor?: string;
  embedded?: boolean;
};

const FAQ: FC<FAQProps> = ({ title, accordionData, backgroundColor, embedded }) => {
  const data = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: accordionData.map(({ header: name, text }) => ({
      '@type': 'Question',
      name,
      acceptedAnswer: {
        '@type': 'Answer',
        text,
      },
    })),
  };

  const content = () => (
    <>
      {!!data.mainEntity?.length && (
        <Head>
          <script
            id="faq-schema"
            key="faq-schema"
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
          />
        </Head>
      )}
      <Wrapper>
        <H2>{title}</H2>
        <FAQList>
          {accordionData.map((data) => (
            <Accordion key={data.header} {...data} />
          ))}
        </FAQList>
      </Wrapper>
    </>
  );

  if (embedded) {
    return content();
  } else {
    return (
      <Container backgroundColor={backgroundColor}>
        <Content>{content()}</Content>
      </Container>
    );
  }
};

export default memo(FAQ);
